import React, {Component} from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import ConfirmationDialog from './ConfirmationDialog'
import { Auth } from '../services/auth'
import { globals } from '../services/globals'
import update from 'immutability-helper'

class TableView extends Component {
    constructor(props) {
        super(props)
        /*
            -Define props
            pageName
            defaultAttr
            tableHeads
            summerizeInfo
            editorDialog
        */
        this.state = {
            list: [],
            selectedIndex: 0,
            selectedItem: {},
            openEditorDialog: false,
            isCreateNew: false,
            openConfirmationDialog: false,
            confirmationDialogOption: '',
            confirmationDialogCallback: null,
        }
    }

    componentDidMount = () => {
        this.refreshList()
    }

    dataFetch = (route, body, callback) => {
        Auth.authedFetch(`${this.props.pageName}/${route}`, body)
        .then(resp => callback(resp))
    }

    refreshList = () => {
        this.dataFetch('list', {}, (resp) => {
            if (resp.status !== 0) {
                console.log(resp)
            }
            this.setState({
                list: resp.list
            })
        })
    }

    handleEditButtonClick = (index) => (event) => {
        this.setState({
            selectedIndex: index,
            selectedItem: this.state.list[index],
            isCreateNew: false,
            openEditorDialog: true,
        })
    }

    closeEditorDialog = (item) => {
        this.setState({
            openEditorDialog: false
        })
        if (item) {
            let payload = {
                'name': item.Name,
                'attr': item.Attr, 
                'info': item.Info
            }
            if (this.state.isCreateNew) {
                this.setState({
                    list: update(this.state.list, {$push: [item]})
                })
            }else{
                this.setState({
                    list: update(this.state.list, {[this.state.selectedIndex]: {$set: item}})
                })
            }
            this.dataFetch('update', payload, (resp) => {
                globals.handleResponse(resp)
            })
        }
    }

    handleAddButtonClick = (event) => {
        const newItem = {
            'Name': '',
            'Attr': this.props.defaultAttr,
            'Info': []
        }
        this.setState({
            selectedItem: newItem,
            isCreateNew: true,
            openEditorDialog: true
        })
    }

    handleDeleteButtonClick = (index) => (event) => {
        this.setState({
            selectedIndex: index,
            selectedItem: this.state.list[index],
            confirmationDialogOption: 'delete',
            confirmationDialogCallback: this.closeDeleteConfirmationDialog,
            openConfirmationDialog: true
        })
    }

    closeDeleteConfirmationDialog = (doDelete) => {
        if (doDelete) {
            this.setState({
                openConfirmationDialog: false,
                list: update(this.state.list, {$splice: [[this.state.selectedIndex, 1]]})
            })
            const payload = {
                'name': this.state.selectedItem.Name
            }
            this.dataFetch('delete', payload, (resp) => {
                globals.handleResponse(resp)
            })
        }else{
            this.setState({
                openConfirmationDialog: false
            })
        }
    }

    render () {
        return (
            <div>
            <Table>
                <TableHead>
                    <TableRow>
                        {this.props.tableHeads.map ((head, i) => 
                            <TableCell key={'head-'+i}>{head}</TableCell>
                        )}
                        <TableCell>編集</TableCell>
                        <TableCell>削除</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.list.map( (item, i) => 
                        <TableRow
                            key = {'item-'+i}
                        >
                            <TableCell>
                                {item.Name}
                            </TableCell>
                            <TableCell>
                                {this.props.summarizeInfo(item)}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={this.handleEditButtonClick(i)}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={this.handleDeleteButtonClick(i)}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow key='item-add'>
                            <TableCell
                                colSpan = {this.props.tableHeads.length + 2}
                            >
                                <IconButton
                                    onClick={this.handleAddButtonClick}
                                >
                                    <AddIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                </TableBody>
            </Table>
            {this.state.openEditorDialog && <this.props.editorDialog
                item = {this.state.selectedItem}
                openDialog = {this.state.openEditorDialog}
                closeDialog = {this.closeEditorDialog}
                isCreateNew = {this.state.isCreateNew}
            />}
            {this.state.openConfirmationDialog && <ConfirmationDialog
                option = {this.state.confirmationDialogOption}
                openDialog = {this.state.openConfirmationDialog}
                closeDialog = {this.closeDeleteConfirmationDialog}
            />}
            </div>
        )
    }
}

export default TableView