import React, {Component} from 'react'
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material'

class ConfirmationDialog extends Component {
    constructor (props) {
        super(props)
        this.messageOptions = {
            'delete': '本当に削除しますか？',
            'pdfFailure': 'PDFの読み取りが失敗しました。'
        }
        this.message = this.messageOptions[props.option]
    }

    handleCloseDialog = (doAction) => (event) => {
        this.props.closeDialog(doAction)
    }

    render () {

        return (
            <Dialog
                open = {this.props.openDialog}
                onClose = {this.handleCloseDialog(false)}
            >
                <DialogContent>
                    {this.message}
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleCloseDialog(false)}>取消</Button>
                    <Button variant='contained' onClick={this.handleCloseDialog(true)}>OK</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ConfirmationDialog