import React, {Component} from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { TextField, MenuItem, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import update from 'immutability-helper'

class ProductInfoTable extends Component {
    constructor (props) {
        super(props)
        /*
            -Define props
            item (array)
            updateInfo (function)
        */
        this.state = {
            list: props.item
        }
        this.methods = [
            'Website',
            'Manual',
            'File'
        ]
        this.platforms = [
            'Steam',
            'DLsite',
            'DMM',
            'とらのあな',
            'Steam(Sekai)',
            'Denpasoft',
            'Sekai(Others)'
        ]
    }

    handleMethodTextfieldChange = (index) => (event) => {
        const { value } = event.target
        if (this.state.list[index].Method === 'Manual') {
            this.setState({
                list: update(this.state.list, {[index]:{Method: {$set: value}, Platform: {$set: this.platforms[0]}, ID: {$set: ''}}})
            }, this.liftState)
        }else {
            this.setState({
                list: update(this.state.list, {[index]:{Method: {$set: value}, Platform: {$set: ''}, ID: {$set: ''}}})
            }, this.liftState)
        }
    }
    
    handlePlatformTextfieldChange = (index) => (event) => {
        const { value } = event.target
        this.setState({
            list: update(this.state.list, {[index]:{Platform: {$set: value}}})
        }, this.liftState)
    }

    handleIDTextfieldChange = (index) => (event) => {
        const { value } = event.target
        this.setState({
            list: update(this.state.list, {[index]:{ID: {$set: value}}})
        }, this.liftState)
    }

    handleTitleTextfieldChange = (index) => (event) => {
        const { value } = event.target
        this.setState({
            list: update(this.state.list, {[index]:{Title: {$set: value}}})
        }, this.liftState)
    }

    handleRateTextfieldChange = (index) => (event) => {
        const { value } = event.target
        this.setState({
            list: update(this.state.list, {[index]:{Rate: {$set: value}}})
        }, this.liftState)
    }

    handledAddButtonClick = () => {
        const newItem = {
            'Method': this.methods[0],
            'Platform': this.platforms[0],
            'ID': '',
            'Title': '',
            'Rate': ''
        }
        this.setState({
            list: update(this.state.list, {$push: [newItem]})
        }, this.liftState)
    }

    handleDeleteButtonClick = (index) => (event) => {
        this.setState({
            list: update(this.state.list, {$splice: [[index, 1]]})
        }, this.liftState)
    }

    liftState = () => {
        this.props.updateInfo(this.state.list)
    }

    render () {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>種別</TableCell>
                        <TableCell>業者</TableCell>
                        <TableCell>品番</TableCell>
                        <TableCell>名称</TableCell>
                        <TableCell>掛け率</TableCell>
                        <TableCell>削除</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {this.state.list.map( (info, i) => 
                    <TableRow key={'product-info-'+i}>
                        <TableCell>
                            <TextField
                                select
                                value={info.Method}
                                onChange={this.handleMethodTextfieldChange(i)}
                                size='small'
                                margin = 'none'
                            >
                            {this.methods.map( (method) =>
                            <MenuItem
                                key={'product-method-'+method}
                                value = {method}
                                >
                                {method}
                                </MenuItem>
                            )}
                            </TextField>
                        </TableCell>
                        <TableCell>
                            <TextField
                                select={info.Method !== 'Manual'}
                                value={info.Platform}
                                onChange={this.handlePlatformTextfieldChange(i)}
                                size='small'
                                margin = 'none'
                            >
                            {this.platforms.map( (platform) => 
                            <MenuItem
                                key={'product-platform-'+platform}
                                value = {platform}
                            >
                                {platform}
                            </MenuItem>
                            )}
                            </TextField>
                        </TableCell>
                        <TableCell>
                                <TextField
                                    value={info.ID}
                                    onChange={this.handleIDTextfieldChange(i)}
                                    disabled={info.Method === 'Manual'}
                                    size='small'
                                    margin = 'none'
                                />
                        </TableCell>
                        <TableCell>
                            <TextField
                                value={info.Title}
                                onChange={this.handleTitleTextfieldChange(i)}
                                size='small'
                                margin = 'none'
                            />
                        </TableCell>
                        <TableCell>
                                <TextField
                                    value={info.Rate ? info.Rate : ''}
                                    onChange={this.handleRateTextfieldChange(i)}
                                    size='small'
                                    margin='none'
                                    disabled={info.Platform !== 'とらのあな'}
                                />
                        </TableCell>
                        <TableCell>
                            <IconButton onClick={this.handleDeleteButtonClick(i)}>
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
                <TableRow key='product-info-add'>
                    <TableCell
                        colSpan = {5}
                    >
                        <IconButton onClick={this.handledAddButtonClick}>
                            <AddIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>
                </TableBody>
            </Table>
        )
    }
}

export default ProductInfoTable