import React, {Component} from 'react'
import TitleBar from '../components/TitleBar'

class SystemPage extends Component {
    constructor(props) {
        super(props)
    }

    render () {
        return (
            <div>
            <TitleBar/>
            <br />
            </div>
        )
    }
}

export default SystemPage