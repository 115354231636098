import React, {Component} from 'react'
import TitleBar from '../components/TitleBar'
import SimpleDatePicker from '../components/SimpleDatePicker'
import { Auth } from '../services/auth'
import SalesInfoTable from '../components/SalesInfoTable'
import { List, ListItem } from '@mui/material'
import SalesEditorDialog from '../components/SalesEditorDialog'

class HomePage extends Component {
    constructor(props) {
        super(props)
        this.columnTitles = [
            '業者',
            '名称',
        ]
        this.state = {
            year: null,
            month: null,
            salesDataKeys: null,
            monthlySales: null,
            openEditorDialog: false,
            editorIndex: null
        }
    }

    fetchSales = () => {
        Auth.authedFetch('sales', {
            'year': this.state.year,
            'month': this.state.month
        }).then(resp => {
            if (resp.status !== 0) {
                console.log(resp)
            }else{
                this.setState({
                    salesDataKeys: resp.data['DataKeys'],
                    monthlySales: resp.data['Sales']
                })
            }
        })
    }

    setDate = (year, month) => {
        this.setState({
            year: year,
            month: month
        }, this.fetchSales)
    }

    handleYenRateChange = (event) => {
        const { value } = event.target
        this.setState({
            yenRate: value
        })
    }

    handleOpenEditorDialog = (index) => {
        this.setState({
            openEditorDialog: true,
            editorIndex: index
        })
    }

    closeEditorDialog = (item) => {
        this.setState({
            openEditorDialog: false
        })
        console.log(item)
    }

    render () {
        return (
            <div>
            <TitleBar/>
            <br />
            <SimpleDatePicker
                setDate = {this.setDate}
                year = {this.state.year}
                month = {this.state.month}
            />
            
            <List>
                {this.state.monthlySales && this.state.monthlySales.map( (productSales, index) => 
                    <ListItem
                        key={'sales-info-table-'+productSales.Name}
                    >
                        <SalesInfoTable
                            columnTitles = {this.columnTitles}
                            salesDataKeys = {this.state.salesDataKeys}
                            index = {index}
                            productSales = {productSales}
                            yenRate = {this.state.yenRate}
                            handleOpenEditorDialog = {this.handleOpenEditorDialog}
                        />
                    </ListItem>
                )}
            </List>
            {this.state.openEditorDialog && <SalesEditorDialog
                item = {this.state.monthlySales[this.state.editorIndex]}
                openDialog = {this.state.openEditorDialog}
                closeDialog = {this.closeEditorDialog}
                columnTitles = {this.columnTitles}
            />}
            </div>
        )
    }
}

export default HomePage