import { globals } from './globals'
import decode from 'jwt-decode'

async function unAuthedFetch(route, body = {}) {
    const res = await fetch(`${globals.apiURL}/auth/${route}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    })
    const result = await res.json()
    return result
}

async function login (token) {
    localStorage.setItem('token', token)
    window.location.replace('/')
}
    
function logout () {
    localStorage.removeItem('token')
    window.location.replace('/')
}

function isLoggedIn () {
    const token = localStorage.getItem('token')
    return (token && !isTokenExpired(token))
}

function isTokenExpired (token) {
    const decoded = decode(token)
    return (decoded.exp < Date.now() / 1000)
}

async function authedFetch (route, body = {}) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    return fetch(`${globals.apiURL}/${route}` , {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
    })
    .then( (res) => {
        if (res.status !== 200){
            return null
        }else {
            return res.json()
        }
    })
}

async function authedUpload (route, file) {
    const headers = {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    let formData = new FormData()
    formData.append('file', file)
    return fetch(`${globals.apiURL}/${route}` , {
        method: 'POST',
        headers: headers,
        body: formData
    })
    .then( (res) => {
        if (res.status !== 200){
            return null
        }else {
            return res.json()
        }
    })
}

export const Auth = {
    login,
    logout,
    isLoggedIn,
    authedFetch,
    authedUpload
}