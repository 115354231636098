import React, { Component } from 'react'
import { Box } from '@mui/material'
import { Auth } from '../services/auth'
import { Navigate } from 'react-router-dom'

const center = {
    'margin': '0',
    'position': 'absolute',
    'top': '50%',
    'left': '50%',
    'msTransform': 'translate(-50%, -50%)',
    'transform': 'translate(-50%, -50%)'
}

class LoginPage extends Component {

    componentDidMount () {
        const searchParams = new URLSearchParams(document.location.search)
        const localToken = searchParams.get('token')
        if (localToken !== null) {
            Auth.login(localToken)
        }else{
            const googleScript = document.createElement('script')
            googleScript.src = 'https://accounts.google.com/gsi/client'
            googleScript.async = true
            googleScript.onload = () => this.scriptLoaded()

            document.head.append(googleScript)
        }
    }

    scriptLoaded () {

    }

    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        })
    }

    render() {
        return (
            <Box sx = {center}>
                {Auth.isLoggedIn() && (
                    <Navigate to="/" replace={true} />
                )}
                <div>
                    <div id="g_id_onload"
                        data-client_id="184433329339-5uj326a67o21cqta1364m1o01kk6peli.apps.googleusercontent.com"
                        data-context="signin"
                        data-ux_mode="redirect"
                        data-login_uri= {process.env.NODE_ENV === 'production' ? 'https://amanita.dev/api/auth/login' : 'http://localhost:7788/api/auth/login'}
                        data-auto_prompt="false"
                        data-use_fedcm_for_prompt="true"
                    >
                    </div>
                    <div class="g_id_signin"
                        data-type="standard"
                        data-shape="rectangular"
                        data-theme="outline"
                        data-text="signin_with"
                        data-size="large"
                        data-logo_alignment="left">
                    </div>
                </div>
            </Box>
        )
    }

}

export default LoginPage