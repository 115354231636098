import React, {Component} from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Box, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

class TransactionsInfoTable extends Component {
    constructor (props) {
        super(props)
        this.state = {
            openConfirmationDialog: false,
        }
    }

    handleDeleteButtonClick = (entryIndex, recordIndex) => (event) => {
        this.props.handleDelete(entryIndex, recordIndex)
    }

    render () {
        return (
            <Box sx={{'width':'90%', 'paddingLeft': '5%'}}>
            <Table>
                <TableHead>
                    <TableRow>
                        {this.props.labels.map( (label, index) => 
                            <TableCell key={'label-cell-'+this.props.keys[index]}>
                                {label}
                            </TableCell>
                        )}
                        <TableCell>
                            削除
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(this.props.list &&  this.props.list.length > 0)? this.props.list.map( (entry, entryIndex) => 
                        entry.Transactions.map( (record, recordIndex) => 
                            <TableRow key={`table-row-${entryIndex}-${recordIndex}`}>
                                {this.props.keys.map( (key) => 
                                <TableCell
                                    key={`data-cell-${key}-${entryIndex}-${recordIndex}`}>
                                    { (key === 'Date' || key === 'Sender') ? entry[key] : record[key]}
                                </TableCell>
                                )}
                                <TableCell>
                                <IconButton onClick={this.handleDeleteButtonClick(entryIndex, recordIndex)}>
                                    <DeleteIcon />
                                </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    ) : 
                    <TableRow>
                        <TableCell colSpan={this.props.labels.length + 1}>データがありません。</TableCell>
                    </TableRow>}
                </TableBody>
            </Table>
            </Box>
        )
    }

}
export default TransactionsInfoTable