import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Toolbar, Tabs, Tab, Button } from '@mui/material'
import { Auth } from '../services/auth'

class TitleBar extends Component {
    handleLogoutClick = () => {
        Auth.logout()
    }

    render () {
        return (
            <AppBar position='static'>
            <Toolbar variant='dense'>
            <Tabs
                value={window.location.pathname}
                style={{flex: 1}}
                textColor="inherit"
                indicatorColor="secondary"
            >
                <Tab component={Link} to='/home' label='Home' value='/home'/>
                <Tab component={Link} to='/product' label='Product' value='/product'/>
                <Tab component={Link} to='/transactions' label='Transactions' value='/transactions'/>
                <Tab component={Link} to='/system' label='System' value='/system'/>
            </Tabs>
            <Button
                color='inherit'
                onClick={
                    Auth.isLoggedIn() ? Auth.logout : (() => window.location.replace('/login'))
                }
            >
                {Auth.isLoggedIn() ? 'Logout' : 'Login'}
            </Button>
            </Toolbar>
            </AppBar>
        )
    }
}

export default TitleBar