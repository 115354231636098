import React, {Component} from 'react'
import TitleBar from '../components/TitleBar'
import TableView from '../components/TableView'
import ProductEditorDialog from '../components/ProductEditorDialog'

class ProductPage extends Component {

    summarizeProductInfo = (product) => {
        if (!product.Info || product.Info.length === 0) {
            return ''
        }
        const info = product.Info
        let counter = {}
        for (const entry of info) {
            if (!counter[entry.Method]) {
                counter[entry.Method] = 1
            }else{
                counter[entry.Method] = counter[entry.Method] + 1
            }
        }
        
        let result = ''
        let keys = Object.keys(counter)
        for (let i =0; i < keys.length; i++) {
            const key = keys[i]
            result += `${key} ${counter[key]}`
            if (i < keys.length - 1) result += ', '
        }
        return result
    }

    render () {
        return (
            <div>
            <TitleBar/>
            <br />
            <TableView
                pageName = 'product'
                defaultAttr = {{}}
                tableHeads = {['製品名', '項目数']}
                summarizeInfo = {this.summarizeProductInfo}
                editorDialog = {ProductEditorDialog}
            >
            </TableView>
            </div>
        )
    }
}

export default ProductPage