import React, {Component} from 'react'
import TitleBar from '../components/TitleBar'
import { FileUploader } from 'react-drag-drop-files'
import { Box } from '@mui/system'
import { Button } from '@mui/material'
import { Auth } from '../services/auth'
import TransactionsEditorDialog from '../components/TransactionsEditorDialog'
import SimpleDatePicker from '../components/SimpleDatePicker'
import TransactionsInfoTable from '../components/TransactionsInfoTable'
import ConfirmationDialog from '../components/ConfirmationDialog'

const transactionsLabels =  [
    '売上月',
    '送金人',
    '入金日',
    '原取引金額',
    '送金銀行手数料',
    '送金金額（ドル）',
    '換算相場',
    '換算後金額（円）',
    '種別'
]

const transactionsKeys = [
    'Date',
    'Sender',
    'TransactionDate',
    'OriginalAmount',
    'Fee',
    'USDAmount',
    'Rate',
    'JPYAmount',
    'Type'
]

class TransactionsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pdfFile: null,
            item: null,
            openEditorDialog: false,
            isCreateNew: false,
            year: null,
            month: null,
            transactonsList: null,
            openConfirmationDialog: false,
            confirmationDialogOption: '',
            confirmationDialogCallback: null
        }
    }

    handleFileUploaderChange = (file) => {
        Auth.authedUpload('transactions/upload-pdf', file)
        .then( (resp) => {
            if (resp.status !== 0) {
                console.log(resp)
                this.setState({
                    openConfirmationDialog: true,
                    confirmationDialogOption: 'pdfFailure',
                    confirmationDialogCallback: () => {this.setState({openConfirmationDialog:false})}
                })
            }else{
                this.setState({
                    item: resp.data,
                    isCreateNew: false,
                    openEditorDialog: true
                })
            }
        })
    }

    convertTransactionItem (item) {
        for (let i = 0; i < transactionsKeys.length; i++){
            const key = transactionsKeys[i]
            if (key !== 'Sender' && key !== 'Type') {
                item[key] = parseFloat(item[key])
            }
        }
    }

    closeEditorDialog = (item) => {
        this.setState({
            openEditorDialog: false
        })
        if (item) {
            this.convertTransactionItem(item.item)
            Auth.authedFetch('transactions/add', item)
            .then( (resp) => {
                if (resp.status !== 0) {
                    console.log(resp)
                }else{
                    const year = parseInt(item.item['Date'] / 100)
                    const month = item.item['Date'] % 100
                    this.setDate(year, month)
                }
            })
        }
    }

    handleManualInputButtonClick = (event) => {
        this.setState({
            openEditorDialog: true,
            isCreateNew: true
        })
    }

    fetchTransactions = () => {
        Auth.authedFetch('transactions/list', {
            'year': this.state.year,
            'month': this.state.month
        }).then(resp => {
            if (resp.status !== 0) {
                console.log(resp)

            }else{
                this.setState({
                    transactionsList: resp.list
                })
            }
        })
    }

    handleDelete = (entryIndex, recordIndex) => {
        this.setState({
            openConfirmationDialog: true,
            confirmationDialogOption: 'delete',
            confirmationDialogCallback: this.handleDeleteCallback(entryIndex, recordIndex)
        })
    }

    handleDeleteCallback =  (entryIndex, recordIndex) => (doDelete) => {
        this.setState({
            openConfirmationDialog: false,
            confirmationDialogOption: '',
            confirmationDialogCallback: null
        })
        if (doDelete) {
            const payload = {
                'date': this.state.transactionsList[entryIndex]['Date'],
                'sender': this.state.transactionsList[entryIndex]['Sender'],
                'index': recordIndex
            }
            Auth.authedFetch('transactions/delete', payload)
            .then( resp => {
                if (resp.status !== 0) {
                    console.log(resp)
                }else{
                    this.fetchTransactions()
                }
            })
        }
    }

    setDate = (year, month) => {
        this.setState({
            year: year,
            month: month
        }, this.fetchTransactions)
    }

    render () {
        return (
            <div>
            <TitleBar/>
            <br />
            <div>
            <Box sx = {{ width: '400px', margin: 'auto'}} >
            <FileUploader
                    handleChange={this.handleFileUploaderChange}
                    types={['PDF']}
                />
            </Box>
            <Box sx = {{ width: '100px', paddingTop:'10px', display: 'block', margin:'auto'}}>
                <Button
                    variant='contained'
                    onClick={this.handleManualInputButtonClick}
                >
                手動入力
            </Button>
            </Box>

            <SimpleDatePicker
                setDate = {this.setDate}
                year = {this.state.year}
                month = {this.state.month}
            />
            <TransactionsInfoTable
                labels = {transactionsLabels}
                keys = {transactionsKeys}
                list = {this.state.transactionsList}
                handleDelete = {this.handleDelete}
            />
            {this.state.openEditorDialog && <TransactionsEditorDialog
                labels = {transactionsLabels}
                keys = {transactionsKeys}
                item = {this.state.item}
                openDialog = {this.state.openEditorDialog}
                closeDialog = {this.closeEditorDialog}
                isCreateNew = {this.state.isCreateNew}
            />}
            {this.state.openConfirmationDialog && <ConfirmationDialog
                option = {this.state.confirmationDialogOption}
                openDialog = {this.state.openConfirmationDialog}
                closeDialog = {this.state.confirmationDialogCallback}
            />}
            </div>
            </div>
        )
    }
}

export default TransactionsPage