import React, {Component} from 'react'
import { List, ListItem, TextField, Autocomplete, Box } from '@mui/material'
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material'
import update from 'immutability-helper'

const autoCompleteOptions = {
    'Sender': [
        'VALVE CORPORATION',
        'SEKAI PROJECT, INC',
        'CFK CO.,LTD',
        'PEROPERO LTD.'
    ],
    'Type': [
        'N/A',
        'Steam',
        'Package'
    ]
}

class TransactionsEditorDialog extends Component {
    constructor (props) {
        super(props)
        if (props.isCreateNew) {
            let emptyItem = {}
            for (let i = 0; i < props.keys.length; i++) {
                emptyItem[props.keys[i]] = ''
            }
            this.state = {
                item : emptyItem
            }
        } else {
            this.state = {
                item: props.item
            }
        }
    }

    handleCloseDialog = (doSubmit) => (event) => {
        if (doSubmit) {
            this.props.closeDialog(this.state)
        }else{
            this.props.closeDialog(null)
        }
    }

    handleTextFieldChange = (key) => (event, values) => {
        const { value } = event.target
        this.setState({
            item: update(this.state.item, {[key]: {$set: value}})
        })
    }

    handleAutoCompleteChange = (key) => (event, value) => {
        this.setState({
            item: update(this.state.item, {[key]: {$set: value}})
        })
    }

    listItemTextField = (key) => {
        if (key === 'Sender' || key === 'Type') {
            return <Autocomplete
                    value= {this.state.item[key]}
                    options = {autoCompleteOptions[key]}
                    getOptionLabel = { (option) => {return String(option)}}
                    isOptionEqualToValue = { (option, value) => {
                        return String(option) === value
                    }}
                    freeSolo
                    sx = {{'width': '300px'}}
                    onChange = {this.handleAutoCompleteChange(key)}
                    renderInput = {(params) => <TextField {...params}/>}
                    />
        }else{
            return <TextField
                    value = {this.state.item[key]}
                    sx = {{'width': '300px'}}
                    onChange = {this.handleTextFieldChange(key)}
                    type = 'number'
                    />
        }
    }

    render () {
        return (
            <Dialog
                open = {this.props.openDialog}
                onClose = {this.handleCloseDialog(true)}
            >
            <DialogContent>
                <List>
                    {this.props.keys.map( (key, index) =>
                        <ListItem key={'list-item-'+key}>
                            <Box sx={{'width': '150px'}}>
                                {this.props.labels[index]}
                            </Box>
                            {this.listItemTextField(key)}
                        </ListItem>
                    )}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseDialog(false)}>取消</Button>
                <Button variant='contained'
                        onClick={this.handleCloseDialog(true)}
                        disabled={this.state.item['Date'] === '' || this.state.item['Sender'] === ''}
                >保存</Button>
            </DialogActions>
            </Dialog>
        )
    }
}

export default TransactionsEditorDialog