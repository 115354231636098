import React, {Component} from 'react'
import { PrivateRoute } from './components/PrivateRoute'
import { Navigate, BrowserRouter as Router, Route, Routes} from "react-router-dom"
import LoginPage from './pages/LoginPage'
import HomePage from './pages/HomePage'
import ProductPage from './pages/ProductPage'
import TransactionsPage from './pages/TransactionsPage'
import SystemPage from './pages/SystemPage'

class App extends Component {
    render () {
        return (
            <Router>
                <Routes>
                    <Route path='/login' element={<LoginPage/>}/>
                    <Route path='/' element={<PrivateRoute/>}>
                        <Route path='/' element={<Navigate to="/home" />}/>
                    </Route>
                    <Route path='/home' element={<PrivateRoute/>}>
                        <Route path='/home' element={<HomePage/>}/>
                    </Route>
                    <Route path='/product' element={<PrivateRoute/>}>
                        <Route path='/product' element={<ProductPage/>}/>
                    </Route>
                    <Route path='/transactions' element={<PrivateRoute/>}>
                        <Route path='/transactions' element={<TransactionsPage/>}/>
                    </Route>
                    <Route path='/system' element={<PrivateRoute/>}>
                        <Route path='/system' element={<SystemPage/>}/>
                    </Route>
                </Routes>
            </Router>
        )
    }
}

export default App