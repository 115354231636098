import React, {Component} from 'react'
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { TextField } from '@mui/material'

class SalesEditorDialog extends Component {
    componentDidMount () {

    }

    handleCloseDialog = (doSubmit) => (event) => {
        if (doSubmit) {
            this.props.closeDialog(this.state)
        }else{
            this.props.closeDialog(null)
        }
    }
    
    render () {
        return (
            <Dialog
                open = {this.props.openDialog}
                onClose = {this.handleCloseDialog(true)}
                maxWidth = 'lg'
            >
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {this.props.columnTitles.map(columnTitle => 
                                    <TableCell key={'column-title-'+columnTitle}>
                                        {columnTitle}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseDialog(false)}>取消</Button>
                    <Button variant='contained' onClick={this.handleCloseDialog(true)}>保存</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default SalesEditorDialog