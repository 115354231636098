import React, {Component} from 'react'
import { Box, TextField, MenuItem } from '@mui/material'

class SimpleDatePicker extends Component {
    constructor(props) {
        super(props)
        const current = new Date()
        this.yearArr = []
        this.monthArr = []
        for (let i = 2018; i <= current.getFullYear(); i++) {
            this.yearArr.push(i)
        }
        for (let i = 1; i <= 12; i++){
            this.monthArr.push(i)
        }
        let defaultYear = current.getFullYear()
        let defaultMonth = current.getMonth() - 1
        if (defaultMonth < 1) {
            defaultMonth += 10
            defaultYear -= 1
        }
        this.state = {
            selectedYear: defaultYear,
            selectedMonth: defaultMonth
        }
        props.setDate(this.state.selectedYear, this.state.selectedMonth)
    }

    componentDidUpdate (prevProps, prevState) {
        if (prevProps.year !== this.props.year) {
            this.setState({
                selectedYear: this.props.year
            })
        }
        if (prevProps.month !== this.props.month) {
            this.setState({
                selectedMonth: this.props.month
            })
        }
    }

    handleYearSelect = (event) => {
        const { value } = event.target
        this.setState({
            selectedYear: value
        }, this.liftState)
    }

    handleMonthSelect = (event) => {
        const { value } = event.target
        this.setState({
            selectedMonth: value
        }, this.liftState)
    }

    liftState = () => {
        this.props.setDate(this.state.selectedYear, this.state.selectedMonth)
    }
    
    render () {
        return (
            <Box sx={{'display': 'inline-block', 'paddingLeft': '6%', 'paddingRight': '50px'}}>
                <TextField
                    select
                    label='年'
                    value={this.state.selectedYear}
                    onChange={this.handleYearSelect}
                    size='small'
                    margin='none'
                    disabled={this.props.disabled}
                >
                    {this.yearArr.map( (year) =>
                        <MenuItem
                            key={'year-menu-item-'+year}
                            value={year}
                        >
                            {year}
                        </MenuItem>
                    )}
                </TextField>
                <TextField
                    select
                    label='月'
                    value={this.state.selectedMonth}
                    onChange={this.handleMonthSelect}
                    size='small'
                    margin='none'
                    disabled={this.props.disabled}
                >
                    {this.monthArr.map( (month) =>
                        <MenuItem
                            key={'month-menu-item-'+month}
                            value={month}
                        >
                            {month}
                        </MenuItem>
                    )}
                </TextField>
            </Box>
        )
    }
}

export default SimpleDatePicker