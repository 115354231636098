let apiURL = 'http://127.0.0.1:7788/api'

if (process.env.NODE_ENV === 'production') {
    apiURL = '/api'
}

function handleResponse (resp) {
    if (resp.status !== 0) {
        console.log(resp)
    }
}

export const globals = {
    apiURL,
    handleResponse
}