import React, {Component} from 'react'
import { List, ListItem, TextField } from '@mui/material'
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material'
import update from 'immutability-helper'

class EditorDialog extends Component {
    constructor (props) {
        super(props)
        /*
            -Define props
            item
            isCreateNew (bool)
            attrLabels (array)
            infoTable (component)
        */
        this.state = {
            ...props.item
        }
    }

    handleCloseDialog = (doSubmit) => (event) => {
        if (doSubmit) {
            this.props.closeDialog(this.state)
        }else{
            this.props.closeDialog(null)
        }
    }

    handleNameTextfieldChange = (event) => {
        this.setState({
            Name: event.target.value
        })
    }

    handleAttrTextfieldChange = (attrKey) => (event) => {
        const { value } = event.target
        this.setState({
            Attr: update(this.state.Attr, {[attrKey]: {$set: value}})
        })
    }

    updateInfo = (info) => {
        this.setState({
            Info: info
        })
    }

    render () {
        return (
            <Dialog
                open = {this.props.openDialog}
                onClose = {this.handleCloseDialog(true)}
                maxWidth = 'lg'
            >
            <DialogContent>
                <List>
                    <ListItem>
                    <TextField
                        label='名称'
                        fullWidth
                        value={this.state.Name}
                        onChange={this.handleNameTextfieldChange}
                        disabled={!this.props.isCreateNew}
                    />
                    </ListItem>
                    {this.props.isCreateNew && <ListItem>名称は作成後から変更できません。削除して再作成となります。</ListItem>}
                    {this.state.Attr && Object.keys(this.state.Attr).map( (attrKey) => 
                        <ListItem key={attrKey+'-listitem'}>
                        <TextField
                            label = {this.props.attrLabels[attrKey]}
                            fullWidth
                            value={this.state.Attr[attrKey]}
                            onChange={this.handleAttrTextfieldChange(attrKey)}
                        />
                        </ListItem>
                    )}
                    <ListItem>
                        {<this.props.infoTable
                            item = {this.state.Info}
                            updateInfo = {this.updateInfo}
                        />}
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleCloseDialog(false)}>取消</Button>
                <Button variant='contained' onClick={this.handleCloseDialog(true)}>保存</Button>
            </DialogActions>
        </Dialog>
        )
    }
}

export default EditorDialog 