import React, {Component} from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Box } from '@mui/material'

class SalesInfoTable extends Component {
    constructor (props) {
        super(props)
        this.state = {
            openEditorDialog: false
        }
    }

    calculateSum (key) {
        let sum  = 0
        for (const salesEntry of this.props.productSales.Sales) {
            sum += this.convertValue(salesEntry.Platform, salesEntry.Data[key])
        }
        return sum
    }

    convertValue (platform, value) {
        if (platform === 'Steam') {
            return parseInt(value * this.props.yenRate)
        }else{
            return value
        }
    }

    makeDataCell (salesEntry, key) {
        // Multiple sales entries are ignored here
        let value = salesEntry.Data[0][key] !== undefined ? salesEntry.Data[0][key] : '-'
        return <TableCell key={'sales-data-'+key}>{value}</TableCell>
    }

    makeSumCell (key, index) {
        let result = '-'
        if (index > 2) {
            result = this.calculateSum(key)
        }
        return <TableCell key={'sales-sum-'+key}>{result}</TableCell>
    }

    formateDate (date) {
        return `${Math.floor(date/100)}年${date%100}月`
    }

    render () {
        return (
            <Box sx={{'width':'90%', 'paddingLeft': '5%'}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={this.props.columnTitles.length + this.props.salesDataKeys.length}>
                            {this.props.productSales.Name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        {this.props.columnTitles.map(columnTitle => 
                            <TableCell key={'column-title-'+columnTitle}>
                                {columnTitle}
                            </TableCell>
                        )}
                        {this.props.salesDataKeys.map(salesDataKey => 
                            <TableCell key={'column-title-'+salesDataKey}>
                                {salesDataKey}
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.productSales.Sales.map( (salesEntry, i) => 
                        <TableRow key={'sales-info-'+i}>
                            <TableCell>{salesEntry.Platform}</TableCell>
                            <TableCell>{salesEntry.Title}</TableCell>
                            {this.props.salesDataKeys.map( (key, j) => 
                                this.makeDataCell(salesEntry, key)
                            )}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            </Box>
        )
    }
}

export default SalesInfoTable