import React, {Component} from 'react'
import EditorDialog from './EditorDialog'
import ProductInfoTable from './ProductInfoTable'

class ProductEditorDialog extends Component {
    /*
        -Define props
        item
        isCreateNew (bool)
        openDialog (bool)
        closeDialog (function)
        isCreateNew (bool)
    */

    render () {
        return (
            <EditorDialog
                item = {this.props.item}
                isCreateNew = {this.props.isCreateNew}
                attrLabels = {{}}
                openDialog = {this.props.openDialog}
                closeDialog = {this.props.closeDialog}
                infoTable = {ProductInfoTable}
            />
        )
    }
}

export default ProductEditorDialog